import { h } from 'preact'
import classNames from 'clsx'

import styles from './index.module.scss'

const BackgroundTitle = (props) => {
  const {
    children,
    className,
    light,
    small,
    corner,
    border,
    noPadding,
    noMargin,
    level = 1,
    ...rest
  } = props

  const Element = `h${level > 6 || level <= 0 ? 1 : level}`

  return (
    <Element
      {...rest}
      className={buildClassNames({
        className,
        light,
        small,
        corner,
        border,
        noPadding,
        noMargin,
      })}
    >
      {children}
    </Element>
  )
}

function buildClassNames(props) {
  const {
    className,
    light,
    small,
    corner = true,
    border,
    noPadding,
    noMargin,
  } = props

  return classNames(
    className,
    styles['a-background-title'],
    {
      [styles['a-background-title--light']]: light,
      [styles['a-background-title--small']]: small,
      [styles['a-background-title--corner']]: corner,
      [styles['a-background-title--border']]: border,
      [styles['a-background-title--no-padding']]: noPadding,
      [styles['a-background-title--no-margin']]: noMargin,
    },
  )
}

export default BackgroundTitle
